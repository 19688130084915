import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import BlogTopStory from "../BlogTopStory"
import {
  SmallBlueCircle,
  SmallGreenCircle,
  BigWhiteCircle,
} from "./BlogTopStoryCircles"
import { ScreensMediaSizes } from "@helpers/helpers"
import useMediaQuery from "@hooks/useMediaQuery"
import { BlogPostDataType } from "@typings/requests/home/blog-post"
import "./blog-top-story-section.scss"

interface BlogTopStorySectionPropsTypes {
  post: BlogPostDataType
  title: string
}

const BlogTopStorySection: React.FC<BlogTopStorySectionPropsTypes> = ({
  title,
  post,
}) => {
  const isMobileMatch = useMediaQuery(ScreensMediaSizes.mobile)
  const [isInView, setIsInView] = useState(false)

  return (
    <InView
      as="section"
      triggerOnce
      className="blog-top-story"
      threshold={[0.1]}
      onChange={setIsInView}
    >
      <div className="container">
        <SmallBlueCircle isMobileMatch={isMobileMatch} isView={isInView} />
        <SmallGreenCircle isMobileMatch={isMobileMatch} isView={isInView} />
        <BigWhiteCircle isMobileMatch={isMobileMatch} isView={isInView} />
        <h2 className="blog-top-story__title text text--sub1">{title}</h2>
        <BlogTopStory {...post} />
      </div>
    </InView>
  )
}

export default BlogTopStorySection
