import React, { useCallback } from "react"
import cn from "classnames"
import { BlogTagsDataType } from "@typings/requests/home/blog"
import "./blog-tags-nav.scss"

interface BlogTagsNavPropsTypes {
  activeFilter: string
  setActiveFilter: (activeFilter: string) => void
  tags: BlogTagsDataType[]
}

const BlogTagsNav: React.FC<BlogTagsNavPropsTypes> = ({
  tags,
  activeFilter,
  setActiveFilter,
}) => {
  const handleActiveFilter = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    e => {
      if (e.target instanceof HTMLButtonElement) {
        const filter = e.target.dataset.filter || ""
        setActiveFilter(filter)
      }
    },
    [setActiveFilter]
  )

  return (
    <section className="blog-tags">
      <div className="container">
        <div className="container__item">
          <ul className="blog-tags__list list-unstyled">
            <li className="blog-tags__item">
              <button
                className={cn(
                  "blog-tags__button button button--extra-small button--white button--rounded",
                  {
                    "blog-tags__button--active": activeFilter === "All",
                    "button--disabled": activeFilter === "All",
                  }
                )}
                data-filter="All"
                disabled={activeFilter === "All"}
                onClick={handleActiveFilter}
              >
                All
              </button>
            </li>
            {tags.map(tag => (
              <li className="blog-tags__item" key={tag.id}>
                <button
                  className={cn(
                    "blog-tags__button  button button--extra-small button--white button--rounded",
                    {
                      "blog-tags__button--active": activeFilter === tag.name,
                      "button--disabled": activeFilter === tag.name,
                    }
                  )}
                  onClick={handleActiveFilter}
                  data-filter={tag.name}
                  disabled={activeFilter === tag.name}
                >
                  {tag.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default BlogTagsNav
