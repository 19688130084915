import React from "react"
import cn from "classnames"
import { Parallax } from "react-scroll-parallax"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const BigBlueCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
  isMobileMatch,
}) => (
  <div
    className={cn("blog-hero-circle blog-hero-circle--big-blue", {
      "h-in-view": isView,
    })}
  >
    <Circle isSolid={!isMobileMatch} color="#431B93" />
  </div>
)

export const SmallGreenCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("blog-hero-circle blog-hero-circle--small-green", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[-90, 90]}>
      <Circle color="#009B3A" />
    </Parallax>
  </div>
)
