import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import BlogCard from "../BlogCard"
import { BigBlueCircle, SmallGreenCircle } from "./BlogLatestCircles"
import useMediaQuery from "@hooks/useMediaQuery"
import { ScreensMediaSizes } from "@helpers/helpers"
import { BlogPostDataType } from "@typings/requests/home/blog-post"
import "./blog-latest-posts.scss"

interface BlogLatestPostsPropsTypes {
  posts: BlogPostDataType[]
  title: string
  loadMoreLabel: string
  showLoadMore: boolean
  handleLoadMore: () => void
}

const BlogLatestPosts: React.FC<BlogLatestPostsPropsTypes> = ({
  title,
  posts,
  loadMoreLabel,
  handleLoadMore,
  showLoadMore,
}) => {
  const isMobileMatch = useMediaQuery(ScreensMediaSizes.mobile)
  const [isInView, setIsInView] = useState(false)

  return (
    <InView
      as="section"
      triggerOnce
      className="blog-latest"
      threshold={[0.1]}
      onChange={setIsInView}
    >
      <div className="container">
        <div className="container__item">
          <h2 className="blog-latest__title text text--sub1">{title}</h2>
        </div>
      </div>
      <div className="blog-latest-posts">
        <BigBlueCircle isMobileMatch={isMobileMatch} isView={isInView} />
        <SmallGreenCircle isView={isInView} />
        <ul className="blog-latest__list container">
          {posts.map(post => (
            <li className="blog-latest__list-item" key={post.id}>
              <BlogCard
                {...post}
                image={post.image?.localFile.childImageSharp.gatsbyImageData}
              />
            </li>
          ))}
        </ul>
      </div>
      {showLoadMore && (
        <div className="container">
          <div className="container__item">
            <button
              onClick={handleLoadMore}
              className="blog-latest__button button button--text button--transparent button--extra-small"
            >
              {loadMoreLabel}
            </button>
          </div>
        </div>
      )}
    </InView>
  )
}

export default BlogLatestPosts
