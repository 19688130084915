import React, { useMemo } from "react"
import cn from "classnames"

import "../../styles/base.scss"
import "../../styles/variables.scss"
import "../../styles/typo.scss"

import BlogHero from "@components/Blog/BlogHero"
import BlogTagsNav from "@components/Blog/BlogTagsNav"
import BlogFeaturedPosts from "@components/Blog/BlogFeaturedPosts"
import BlogTopStorySection from "@components/Blog/BlogTopStorySection"
import BlogLatestPosts from "@components/Blog/BlogLatestPosts"
import BlogBottomHero from "@components/Blog/BlogBottomHero"
import { useBlogFilterContext } from "./BlogFilterContext"
import {
  BlogFilterContextType,
  BlogPagePropsTypes,
} from "@typings/containers/blog"

const BlogPage: React.FC<BlogPagePropsTypes> = ({ data, lang = "en" }) => {
  const { allPosts, featuredPosts, topPost, blog, tags, allStrapiPageFooter } =
    data

  const [blogData] = blog.nodes
  const { activeFilter, limit, setActiveFilter, handleLoadMore } =
    useBlogFilterContext() as BlogFilterContextType

  const filteredPosts = useMemo(() => {
    if (activeFilter === "All") {
      return allPosts.nodes
    }
    return allPosts.nodes.filter(post =>
      post.tags.some(tag => tag.name === activeFilter)
    )
  }, [activeFilter, allPosts.nodes])

  const visiblePosts = useMemo(
    () => filteredPosts.slice(0, limit),
    [filteredPosts, limit]
  )

  const blogPostsTitle = useMemo(() => {
    if (activeFilter === "All") {
      return blogData.latestTitle
    }
    return tags.nodes.filter(tag => tag.name === activeFilter)[0].title
  }, [activeFilter, blogData.latestTitle, tags.nodes])

  return (
    <>
      <BlogHero
        lang={lang}
        title={blogData.heroTitle}
        description={blogData.heroDescription}
        searchLabel={blogData.searchLabel}
        searchTypoMore={blogData.searchTypoMore}
        searchNothingFound={blogData.searchNothingFound}
      />
      <BlogTagsNav
        tags={tags.nodes}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <div
        className={cn("blog-hidden", {
          "blog-hidden--hidden": activeFilter !== "All",
        })}
      >
        {featuredPosts.nodes.length > 0 && (
          <BlogFeaturedPosts
            posts={featuredPosts.nodes}
            title={blogData.featuredTitle}
          />
        )}
        {topPost.nodes.length > 0 && (
          <BlogTopStorySection
            post={topPost.nodes[0]}
            title={blogData.storyTitle}
          />
        )}
      </div>
      {allPosts.nodes.length > 0 && (
        <BlogLatestPosts
          handleLoadMore={handleLoadMore}
          showLoadMore={filteredPosts.length > limit}
          posts={visiblePosts}
          title={blogPostsTitle}
          loadMoreLabel={blogData.loadMore}
        />
      )}
      <BlogBottomHero
        {...blogData}
        lang={lang}
        footerData={allStrapiPageFooter}
      />
    </>
  )
}

export default BlogPage
