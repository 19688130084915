import React from "react"
import cn from "classnames"
import { Parallax } from "react-scroll-parallax"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const SmallBlueCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isMobileMatch,
  isView,
}) => (
  <div
    className={cn("blog-top-story-circle blog-top-story-circle--small-blue", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[30, -30]}>
      <Circle isSolid={!isMobileMatch} color="#431B93" />
    </Parallax>
  </div>
)

export const SmallGreenCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isMobileMatch,
  isView,
}) => (
  <div
    className={cn("blog-top-story-circle blog-top-story-circle--small-green", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[-20, 20]}>
      <Circle color="#009B3A" isSolid={!isMobileMatch} />
    </Parallax>
  </div>
)

export const BigWhiteCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isMobileMatch,
  isView,
}) => (
  <div
    className={cn("blog-top-story-circle blog-top-story-circle--big-white", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[-10, 10]}>
      <Circle color="#FFFFFF" isSolid={!isMobileMatch} />
    </Parallax>
  </div>
)
