import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ReactComponent as Arrow } from "@images/icons/arrow-readmore.svg"
import { getLocalePrefix } from "@helpers/helpers"
import { BlogPostDataType } from "@typings/requests/home/blog-post"
import "./blog-top-story.scss"
import TransitionFadeLink from "@components/UI/TransitionFadeLink"

type BlogTopStoryPropsTypes = BlogPostDataType

const BlogTopStory: React.FC<BlogTopStoryPropsTypes> = ({
  title,
  image,
  slug,
  postDescription,
  locale,
}) => {
  return (
    <div className="blog-top-story-card">
      <div className="blog-top-story-card__info">
        <h3 className="blog-top-story-card__title text text--h2">{title}</h3>
        <p className="blog-top-story-card__description text">
          {postDescription}
        </p>
        <TransitionFadeLink
          className="blog-top-story-card__link text text--bold"
          url={`${getLocalePrefix(locale)}/blog${slug}`}
        >
          Read Full
          <span className="blog-top-story-card__icon">
            <Arrow />
          </span>
        </TransitionFadeLink>
      </div>

      <GatsbyImage
        className="blog-top-story-card__picture"
        image={image.localFile.childImageSharp.gatsbyImageData}
        alt={title}
      />
    </div>
  )
}

export default BlogTopStory
