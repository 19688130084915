import React, { useContext } from "react"
import useUpdatePayload from "@hooks/useUpdatePayload"
import {
  BlogFilterContainerPropsTypes,
  BlogFilterContextType,
  BlogFilterPayloadType,
} from "@typings/containers/blog"

const BlogFilterContext = React.createContext<BlogFilterContextType | null>(
  null
)
export const useBlogFilterContext = () => useContext(BlogFilterContext)

const BlogFilterContextContainer: React.FC<BlogFilterContainerPropsTypes> = ({
  children,
}) => {
  const [payload, updatePayload] = useUpdatePayload<BlogFilterPayloadType>({
    limit: 4,
    skip: 4,
    activeFilter: "All",
  })

  const setActiveFilter = (activeFilter: string) => {
    updatePayload({
      activeFilter: activeFilter,
      limit: payload.skip,
    } as BlogFilterPayloadType)
  }

  const handleLoadMore = () => {
    updatePayload({
      limit: payload.limit + payload.skip,
    } as BlogFilterPayloadType)
  }

  const contextValue = {
    ...payload,
    setActiveFilter,
    handleLoadMore,
  }

  return (
    <BlogFilterContext.Provider value={contextValue}>
      {children}
    </BlogFilterContext.Provider>
  )
}

export default BlogFilterContextContainer
