import React from "react"
import { graphql, PageProps } from "gatsby"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { ParallaxProvider } from "react-scroll-parallax"
import { BlogFilterContext, BlogPageContainer } from "@containers/Blog"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import { BlogPageDataType } from "@typings/requests/home/blog"
import { CAPTCHA_KEY } from "@helpers/constants"
import "./blog.scss"

const BlogPage: React.FC<PageProps<BlogPageDataType>> = props => {
  const {
    blog: {
      nodes: [blogData],
    },
  } = props.data

  return (
    <>
      <SEO
        title={blogData.title}
        description={blogData.heroDescription}
        pathname={props.location.pathname}
        image={blogData.image.localFile.url}
        lang="en"
      />
      <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
        <BlogFilterContext>
          <ParallaxProvider>
            <LayoutContainer
              className="h-bg-yellow"
              location={props.location}
              lang="en"
            >
              <BlogPageContainer data={props.data} lang="en" />
            </LayoutContainer>
          </ParallaxProvider>
        </BlogFilterContext>
      </GoogleReCaptchaProvider>
    </>
  )
}

export const query = graphql`
  {
    allPosts: allStrapiPosts(
      filter: { locale: { eq: "en" } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        slug
        id
        locale
        postDescription
        publishedAt(formatString: "DD MM, YYYY", locale: "en")
        tags {
          name
          title
          id
        }
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: CONSTRAINED
                width: 573
                height: 322
              )
            }
          }
        }
        text {
          data {
            id
            text
          }
        }
      }
    }
    featuredPosts: allStrapiPosts(
      limit: 3
      filter: { locale: { eq: "en" }, isFeatured: { eq: true } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        slug
        id
        locale
        publishedAt(formatString: "YYYY-MM-DD", locale: "en")
        tags {
          name
          title
          id
        }
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: CONSTRAINED
                width: 375
                height: 225
              )
            }
          }
        }
      }
    }
    topPost: allStrapiPosts(
      limit: 1
      filter: { locale: { eq: "en" }, isTopStory: { eq: true } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        slug
        title
        locale
        readFullLabel
        postDescription
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 95
                layout: FIXED
                width: 297
                height: 297
              )
            }
          }
        }
      }
    }
    blog: allStrapiBlog(filter: { locale: { eq: "en" } }) {
      nodes {
        featuredTitle
        heroDescription
        heroTitle
        image {
          localFile {
            publicURL
          }
        }
        latestTitle
        loadMore
        locale
        storyTitle
        subscribeButton
        subscribeInputError
        subscribeInputLabel
        subscribeTitle
        subscribeSuccessLabel
        subscribeFailLabel
        searchLabel
        searchTypoMore
        searchNothingFound
        title
        subscribeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 561)
            }
          }
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
    tags: allStrapiTags(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        name
        id
      }
    }
  }
`
export default BlogPage
