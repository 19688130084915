import React from "react"
import cn from "classnames"
import { Parallax } from "react-scroll-parallax"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const BigBlueCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
  isMobileMatch,
}) => (
  <div
    className={cn("blog-latest-circle blog-latest-circle--big-blue", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[10, -10]}>
      <Circle isSolid={isMobileMatch} color="#431B93" />
    </Parallax>
  </div>
)

export const SmallGreenCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("blog-latest-circle blog-latest-circle--small-green", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[-20, 20]}>
      <Circle color="#009B3A" />
    </Parallax>
  </div>
)
