import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import { BigBlueCircle, SmallGreenCircle } from "./BlogHeroCircles"
//import BlogSearchContainer from "@containers/Blog/BlogSearchContainer"

import "./blog-hero.scss"

interface BlogHeroSectionPropsTypes {
  title: string
  description: string
  searchLabel: string
  searchTypoMore: string
  searchNothingFound: string
  lang: string
}

const BlogHero: React.FC<BlogHeroSectionPropsTypes> = ({
  title,
  description,
  // searchLabel,
  // searchTypoMore,
  // searchNothingFound,
  // lang,
}) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <InView
      as="section"
      triggerOnce
      className="blog-hero"
      threshold={[0.3]}
      onChange={setIsInView}
    >
      <div className="container">
        <div className="container__item">
          <BigBlueCircle isView={isInView} />
          <SmallGreenCircle isView={isInView} />
          <div className="blog-hero__info">
            <h1 className="blog-hero__title text text--h0">{title}</h1>
            <p className="blog-hero__description text text--sub2">
              {description}
            </p>
            {/*<BlogSearchContainer*/}
            {/*  label={searchLabel}*/}
            {/*  typoMore={searchTypoMore}*/}
            {/*  notFound={searchNothingFound}*/}
            {/*  lang={lang}*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </InView>
  )
}

export default BlogHero
